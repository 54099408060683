//域名请求前缀
let baseUrl =''
if(process.env.NODE_ENV == 'development'){
    //开发环境
    // baseUrl = 'http://www.imservice.cn/'
    baseUrl = 'http://www.imser.cn/'
}else{
    //生产环境
    baseUrl = 'https://imservice.hnbjsj.com/'
}
baseUrl = 'https://imservice.hnbjsj.com/'
//baseUrl = 'http://192.168.1.185:81/'

export default baseUrl