// 表情的文字对应文件名
// import getEmoji from '@/components/emoji/emojiData'
// 表情的base64的数据
// import emojiQQ from '@/assets/emoji/qq/index'

const filters = {
    /**
     * 功能：将时间戳按照给定的 时间/日期 格式进行处理
     * @param {Number} date 时间戳 
     * @param {String} fmtExp 时间格式 'hh:ss'
     * @returns {String} 规范后的 时间/日期 字符串
     */
    fmtDate: function (date, fmtExp) {
        //如果是10位的时间戳  则转换为13位的进行运算
        if(!isNaN(date)){date *=1000}
        date = new Date(date)
        var o = {
            "Y+":date.getFullYear(),//年
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmtExp))
            fmtExp = fmtExp.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmtExp))
                fmtExp = fmtExp.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmtExp;
    },
    //格式化 聊天中的表情
    // fmtMessage:function(data){
    //     // 先正则出 [亲亲]
    //     const search = /(\[.*?\])/ig
    //     let res = data.match(search)
    //     for(let i in res){
    //         let gitName = getEmoji(res[i])
    //         if(gitName){
    //             // 存在 则替换原文
    //             data = data.replace(res[i],"<img src='"+gitName+".gif' />")
    //         }
    //         console.log(gitName,res[i],emojiQQ[gitName])
    //     }
        
    //     // console.log(data,res,'---data')
    //     return data
    // }
}

export default (Vue) => {
    Object.keys(filters).forEach(key => {
        Vue.filter(key, filters[key])
    })
}