// 浏览器缓存

const Storage = {
    set(key,value){
        localStorage.setItem(key,JSON.stringify(value))
    },
    get(key){
        const value = localStorage.getItem(key)
        if(value && value !="undefined" && value!="null"){
            return JSON.parse(value)
        }
        return false;
    },
    remove(key){
        localStorage.removeItem(key)
    }
}

export default Storage