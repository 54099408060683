import { showToast } from '@/utils/comm';


var XBSClass = {
    //引入某资源文件
    _require(url, onload) {
        var doc = document;
        var head = doc.head || (doc.getElementsByTagName("head")[0] || doc.documentElement);
        var node = doc.createElement("script");
        node.onload = onload;
        node.onerror = function () { }
        node.async = true;
        node.src = url[0];
        head.appendChild(node);
    },


    // 移除字符串空
    sTrim(s) {
        return s.replace(/(^\s*)|(\s*$)/g, "");
    },

    //获取请求头
    getAgentString(name) {
        var uaString = window.navigator.userAgent,
            mainString = uaString.slice(uaString.indexOf('?')),
            reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = mainString.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    },


    //构造器
    factory() {
        var XBS_APP = {
            Debug: false,
            isIOS: /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase()), // 判断是否是ios
            isAndroid: /android/.test(window.navigator.userAgent.toLowerCase()), // 判断是否是 安卓
            platform: function () {
                if (this.isIOS) {
                    return 'IOS';
                }
                if (this.isAndroid) {
                    return 'Android';
                }
                return 'H5'
            },
            isAppUA: /xbs_app/.test(window.navigator.userAgent.toLowerCase()), // 是否是app
            // 获取请求头信息
            uaData: function () {
                if (!this.isAppUA) return false;
                var uaString = navigator.userAgent,
                    mainString = uaString.slice(uaString.indexOf('LJYHAPP'));
                mainString = mainString.slice(0, mainString.indexOf('?'));
                var version = mainString.match(/LJYHAPP\/(\S*)\s?\(/)[1],
                    params = mainString.slice(mainString.indexOf('(') + 1, -1).split(';');
                if (this.isIOS) {
                    params[1] = params[1].replace('iOS ', '');
                }
                return {
                    version: version,
                    sys: sTrim(params[0]),
                    sysver: sTrim(params[1]),
                    model: sTrim(params[2])
                }
            },

            // app 调用方法
            _callApp: function (funName, data) {
                console.log('APP调用', funName, data, this.isAppUA ? 'APP环境' : '非APP环境')
                if (!this.isAppUA) {
                    if (this.Debug)
                        showToast(funName + " 非APP访问")
                    // $('body').append("<br>" + funName + " 非APP访问");
                    return false;
                }
                try {
                    data = data || null;
                    if (this.isIOS) {
                        if (data) {
                            window.webkit.messageHandlers[funName].postMessage(data);
                        } else {
                            window.webkit.messageHandlers[funName].postMessage({});
                        }
                    } else {
                        if (data) {
                            window.Android[funName](JSON.stringify(data));
                        } else {
                            window.Android[funName]();
                        }
                    }
                    // console.log(window.Android,JSON.stringify(data),'----window.Android')
                    // if (this.Debug) $('body').append("<br>" + funName + " 调用成功");
                    if (this.Debug) showToast(funName + " 调用成功")
                    return true;
                } catch (e) {
                    console.log(e, '---app调用失败')
                    console.log(e.name, e.message, "调用的错误信息")
                    if (this.Debug) showToast(funName + " 调用失败")
                    return false;
                }
            },

            /**
             * 验证版本号
             * */
            compareVer: function (v, type) {
                if (!this.isAppUA) return false;
                if (v) {
                    var arr1 = !type ? this.uaData().version.split('.') : this.uaData().sysver.split('.');
                    var arr2 = v.split('.'),
                        minLength = Math.min(arr1.length, arr2.length),
                        position = 0,
                        diff = 0;
                    while (position < minLength && ((diff = parseInt(arr1[position]) - parseInt(arr2[position])) == 0)) {
                        position++;
                    }
                    diff = (diff != 0) ? diff : (arr1.length - arr2.length);
                    return diff >= 0;
                } else {
                    console.error('compareVer：请传入版本号字符串');
                }
            },
            TICKET: '',
            __isRunGetTicket: false,
            //获取ticket 登录凭证
            getTicket: function (cb) {
                var that = this;
                if (that.TICKET) {
                    if (typeof (cb) == "function") cb(that.TICKET);
                    return;
                }
                if (typeof window.login_ticketback == "function") {
                    delete window.login_ticketback;
                }
                window.login_ticketback = function (ticket) {
                    console.log(ticket, '=ticket==========APP TICKET CALLBACK==============')
                    that.TICKET = ticket;
                    if (typeof (cb) == "function") cb(ticket);
                }
                return that._callApp('xbs_getTicket');
            },
            /*
             * 检测是否登录
             * */
            isLogin: null,
            checkLogin: function (cb) {
                var that = this;
                // if (!that.isAppUA) return false;
                if (that.isLogin !== null) {
                    if (typeof (cb) == "function") cb(that.isLogin);
                    return that.isLogin;
                }
                if (typeof window.login_callback == "function") {
                    delete window.login_callback;
                }
                window.login_callback = function (res) {
                    that.isLogin = res;
                    if (typeof (cb) == "function") cb(res);
                }
                return that._callApp("isLogin");
            },
            /*
             * 打开登录页
             * */
            openLogin: function () {
                var that = this;
                if (!that.isAppUA) return false;
                return that._callApp("xbs_joinLogin");
            },
            /*
             * 关闭窗口
             * */
            closeWindow: function () {
                if (!this._callApp("xbs_closeWebView")) {
                    return this._callApp("xbs_goback");
                }
                return true;
            },
            /*
             * 返回上一页
             * */
            goBack: function () {
                return this._callApp("xbs_goBack");
            },
            /*
             * 设置标题
             * */
            setTitle: function (title) {
                return this._callApp("xbs_changeTitle", {
                    title: title
                });
            },
            /*
 * 查看图片
 * */
            showImage: function (images, index) {
                var that = this;
                if (!that.isAppUA) return false;
                if (typeof images !== 'object') {
                    images = [images];
                }
                var index = index || 0;
                index = index < 0 ? 0 : index;
                index = index > images.length ? images.length : index;
                return that._callApp("xbs_seeBigPhoto", {
                    imageArr: images,
                    index: index
                });
            },
            /*
                * 复制文字
                * **/
            copyText: function (text) {
                return this._callApp("xbs_copyUrl", {
                    urlStr: text
                });
            },
            /*
 * 打电话
 * */
            callPhone: function (mobile) {
                return this._callApp("xbs_callPhone", {
                    mobile: mobile
                });
            },
            /*
             * 上传图片
             * */
            uploadImage: function (type, allowsEdit, cb) {
                var that = this;
                // if (!that.isAppUA) return false;
                if (typeof window.photo_callback == "function") {
                    delete window.photo_callback;
                }
                window.photo_callback = function (url) {
                    if (typeof (cb) == "function") cb(url);
                }
                return that._callApp("xbs_uploadPhoto", {
                    type: type,
                    allowsEdit: allowsEdit,
                })
            },
            /**
             * app发起支付
             * @param {*} id 支付的订单id
             * @param {*} type 支付的类型
             * @param {*} cb 支付回调方法
             * @returns 
             */
            orderPay(params, cb) {
                var that = this;
                // if (!that.isAppUA) return false;
                if (typeof window.orderPay_callback == "function") {
                    delete window.orderPay_callback;
                }
                window.orderPay_callback = function (data) {
                    if (typeof (cb) == "function") cb(data);
                }
                return that._callApp("xbs_orderPay", params)
            },
            /**
             * 下载文件
             * @param {*} file 文件地址
             * @returns 
             */
            download: function (file) {
                var that = this;
                if (!that.isAppUA) return false;
                return that._callApp("xbs_download", {
                    file: file
                })
            },
            /*
             * 获取用户信息
             * */
            userInfo: null,
            getUserInfo: function (cb) {
                var that = this;
                // if (!that.isAppUA) return false;
                if (typeof window.xbs_getUserInfoCallback == "function") {
                    delete window.xbs_getUserInfoCallback;
                }
                if (that.userInfo !== null) {
                    if (typeof (cb) == "function") cb(userInfo);
                    return true;
                }
                window.xbs_getUserInfoCallback = function (userInfo) {
                    if (that.userInfo) {
                        that.userInfo = userInfo;
                    }
                    if (typeof (cb) == "function") cb(userInfo);
                }
                return that._callApp("xbs_getUserInfoRequest");
            },
            //退出当前账户
            logout: function () {
                var that = this;
                return that._callApp("xbs_logout");
            },

            // 调用app方法  开始录音
            startRecord: function () {
                var that = this;
                return that._callApp("xbs_startRecord");
            },
            // 调用app方法 结束录音
            stopRecord: function (params, cb) {
                var that = this;
                // 检查window是否存在 xbs_stopRecordCallback 方法 如果有就删除它
                if (typeof window.xbs_stopRecordCallback == "function") {
                    delete window.xbs_stopRecordCallback;
                }
                // 给window 对象中 增加 xbs_stopRecordCallback 方法
                window.xbs_stopRecordCallback = function (file) {
                    console.log('xbs_stopRecordCallback 被回调了')
                    if (typeof (cb) == "function") cb(file);
                }
                // 调用 app 中的 xbs_stopRecord 方法
                return that._callApp("xbs_stopRecord", params);
            },
            // 放弃录制  直接中止录音功能，不需要返回文件
            closeRecord: function () {
                var that = this;
                return that._callApp("xbs_closeRecord");
            }
        }

        // XBS_APP.Debug && alert(navigator.userAgent)
        // XBS_APP.Debug && showToast('当前环境：'+XBS_APP.platform()+"\r\n  是否在APP内："+(XBS_APP.isAppUA?'是':'否'))
        return XBS_APP;
    },
}

export default XBSClass;