const mutations = {
    // 设置iframe窗体信息
    setIframe(state,data){
        if(data.url != undefined) state.iframe.url = data.url
        if(data.show != undefined) state.iframe.show = data.show
        console.log(state.iframe,'---设置的窗体')
    },
    //切换语言 后期需要
    switchLang(state, lang) {
        state.currentLang = lang
        // Vue.config.lang = lang
        document.cookie = "VR_LANG=" + lang + "; path=/;domain=.snail.com"
        // location.reload()
    },
    //设置当前页面名字
    setPageName(state, name) {
        state.currentPageName = name
    },
    //设置前一页名字 已遗弃
    // setBackPageName(state, name) {
    //     state.backPageName = name
    // },
    //当 search 组件全屏/非全屏时 切换公共头部状态
    toggleHeaderStatus(state, status) {
        state.headerStatus = status
    },
    //切换“微信”页中右上角菜单
    toggleTipsStatus(state, status) {
        if (status == -1) {
            state.tipsStatus = false
        } else {
            state.tipsStatus = !state.tipsStatus
        }
    },
    //设置某个属性
    setData(state,data){
        let {key,value} = data
        // console.log(key,value,state[key],'---设置属性')
        state[key] = value
    },
    //设置会话消息列表
    setMsgList(state,data){
        let baseMsg = Object.assign([],data)
        state.msgList.baseMsg = ListSore(baseMsg)

        mutations.setPageRefresh(state,{page:'huihuaStatus',status:false})
    },
    // 更新会话列表的排序
    upMsgListSort(state){
        state.msgList.baseMsg = ListSore(state.msgList.baseMsg)
    },
    //会话列表 追加数据
    addMsgList(state,midInfo){
        let mid = midInfo['mid']
        let baseMsg = state.msgList.baseMsg
        // baseMsg[mid] = {...midInfo}
        baseMsg.push(midInfo)
        state.msgList.baseMsg = Object.assign([],baseMsg)
    },
    //从会话列表中 移除某个会话
    delMsgList(state,mid){
        for(let i in state.msgList.baseMsg){
            let item = state.msgList.baseMsg[i]
            if(item.mid==mid){
                state.msgList.baseMsg.splice(i,1)
                return ;
            }
        }
    },
    //设置通讯录列表
    setFriendList(state,data){
        state.allContacts = data
        // 关闭强制刷新
        mutations.setPageRefresh(state,{page:'contactStatus',status:false})
    },
    //设置聊天记录列表
    setMessageList(state,data){
        let {mid,msgList,page} = data
        state.messageList[mid] = {
            page:page,
            list:msgList,
        }
        // 需要copy 更新对象地址，计算属性才能更新
        let messageList = Object.assign({},state.messageList)
        state.messageList = messageList
    },
    //追加一条聊天记录
    addMessageList(state,data){
        let {mid,msgList} = data
        // console.log('追加消息',mid,msgList)

        // 如果有聊天记录 就增加数据
        if(state.messageList[mid]){
            //消息id
            let msgId = msgList?.id || 0
            if(msgId){
                //检索消息列表 是否有当前消息 有则不增加
                let length = state.messageList[mid].list.length
                let number = 0
                for(let i = length-1;i>0;i--){
                    let item = state.messageList[mid].list[i]
                    if(item.id==msgId){
                        return false;
                    }
                    if(number>=5){
                        break;
                    }
                    number++
                }
            }
            state.messageList[mid].list.push(msgList)
        }

        //修改聊天数据中的最后一句
        for(let i in state.msgList.baseMsg){
            let item = state.msgList.baseMsg[i]
            if(item.mid == mid){
                item.msg_list = Object.assign({},msgList)
                //如果不是当前聊天对象  就要增加未读消息数量
                if(state.mid != mid){
                    item.msg_reda_number++;// 增加未读消息数量
                }
                //如果会话列表是隐藏的  则打开它
                if(item.is_show==0){
                    item.is_show = 1;
                }
                break;
            }
        }
        // 会话列表 进行排序
        state.msgList.baseMsg = ListSore(state.msgList.baseMsg)
        
        //如果不是自己发送的新消息 则设置为新消息提示音
        if(msgList.from_user_id != state.uid){
            state.msgList.newMessage = true
        }
    },
    //向消息列表头部插入一条记录
    pushMessageInfo(state,data){
        let {mid,msgList,page} = data
        if(!state.messageList[mid]){
            state.messageList[mid] = []
        }
        state.messageList[mid].page = page

        state.messageList[mid].list = [...msgList,...state.messageList[mid].list]
        console.log(state.messageList[mid].list.length,'---消息总数量')
    },

    //设置群组信息
    setGroupInfo(state,data){
        let {groupInfo,groupId} = data
        state.groupList[groupId] = groupInfo
        // 需要copy 更新对象地址，计算属性才能更新
        let groupList = Object.assign({},state.groupList)
        state.groupList = groupList
    },

    //设置页面的强制刷新
    setPageRefresh(state,data){
        let {page,status} = data
            console.log('--强制刷新',page,status)
            state.refreshView[page] = status
    },
    //将消息置顶 待完成
    // setMsgStick(state, mid) {

    // },
    //取消置顶消息 待完成
    // cancelMsgStick(state, mid) {

    // }
    //退出登录 清理信息资料
    logOut(state){
        //清理自己的个人信息
        state.userInfo = {}
        //清理所有联系人
        state.allContacts = []
        // 清理会话列表
        state.msgList.baseMsg = []
    }
}

//对会话列表进行排序
const ListSore = (dataList)=>{
    return dataList.sort(function(x,y){
        if(x.is_top==y.is_top){
            //相等的情况 按照最后一条消息时间来排序
            let x_time = x?.msg_list?.create_time || 0
            let y_time = y?.msg_list?.create_time || 0
            
            //如果是文本格式时间 则需要转换为时间戳
            if(isNaN(x_time)) x_time = new Date(x_time).getTime()/1000
            if(isNaN(y_time)) y_time = new Date(y_time).getTime()/1000

            return x_time == y_time ? 0 : (x_time < y_time ? 1 : -1) 
        }else{
            return x.is_top==1 ? -1 : 1
        }
    })
}


export default mutations