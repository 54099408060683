import { ApiGetDialogueList } from '@/api/api/main'
import store from '@/vuex/store'
import router from "../router";
import Base64 from 'base-64'
import XBS_APP from './interactApp';

//消息结构体
const message = function(uid){
    const msg = {
        uid:uid
    }

    // 检查某个用户 是否存在我的会话列表中
    const getIsExistList = (mid)=>{
        for(let i in store.state.msgList.baseMsg){
            let item = store.state.msgList.baseMsg[i]
            if(item.mid == mid){
                return item
            }
        }
        return false;
    }

    //添加消息  如果消息已读 就返回 当前用户id 否则返回 0
    msg.addMessageList = function(data){
            // 如果是群组 那么 对方消息下标是 to_user_id  如果是私聊 消息下标是发送消息的人
            let mid = data['is_group']==1? data['to_user_id']:data['from_user_id']
            // 新消息
            if(XBS_APP.isAppUA){
                XBS_APP.pushAppNew();
            }

            if(data.isMy == 1){
                // mid 是对方， 因为这个消息是需要自己接收的
                mid = data['to_user_id']
            }
            // from_user_headimg  from_user_nickname  msg
            data.tag = 'message'
            store.commit("setData", {
                key:'LastMessage',
                value: data,
            });

            // 判断用户是否存在于会话列表中
            let isExist = getIsExistList(mid)
            // console.log(isExist,'--是否在列表',mid)
            //如果会话列表有该用户 就追加
            if(isExist){
                // console.log('成功追加消息',mid,data.msg)
                //发送消息成功 客户端给视图追加消息
                store.commit("addMessageList", {
                    mid: mid,
                    msgList: data,
                });
                // 滚动条 到底部

                // 如果在聊天列表内
                if(document.getElementById("msg-list")?.scrollTop){
                    setTimeout(() => {
                        document.getElementById("msg-list").scrollTop =
                        document.getElementById("msg-list").scrollHeight;
                    }, 10);
                }
                return mid == store.state.mid ? mid : 0
            }else{
                // 全量更新会话列表
                ApiGetDialogueList().then((res)=>{
                    let data = res.data
                    store.commit('setMsgList',data)
                })
            }


    }

    // 更新上下线状态
    msg.upUserStatus = function(data){
        //好友上下线
        let dialogue = getIsExistList(data.uid)
        if(dialogue){
            // 更新好友的在线离线状态
            dialogue.online = data.status
            let msg 
            if(data.status==1){
                msg = '嗨，我上线了'
            }else{
                msg = '我下线了，拜拜'
            }
            let LastMessage = {
                from_user_nickname:dialogue?.getClientMid?.nickname || '未知好友',
                msg:msg,
                tag:'online',
                from_user_headimg:dialogue?.getClientMid?.headimg || 'https://s3.bmp.ovh/imgs/2022/03/e3fcc12399b93a70.jpg'
            }
            store.commit("setData", {
                key:'LastMessage',
                value: LastMessage,
            });
            console.log(LastMessage.from_user_nickname,msg)
        }
        // console.log(data,dialogue,'---好友上下线')
    }

    //更改群昵称信息
    msg.upGroupName = function (data){
        let groupId = data['groupId']
        let newName = data['newName']
        // 在会话列表中寻找到该群
        let groupInfo = getIsExistList(groupId)
        if(groupInfo){
            // 更新为新的群昵称
            groupInfo.getUserGroup.name = newName
        }
        
        //在群组列表里面 找到并改变
        if(store.state.groupList[groupId]?.name){
            store.state.groupList[groupId].name = newName
        }

        console.log(store.state.groupList,'---groupList')
        // console.log(groupId,newName,groupInfo,'---更新群昵称')
    }

    //新的好友申请
    msg.upNewFriendNumber = function(data){

    }

    // 收到了 音视频消息
    msg.getVideoMsg = function(data){
        data.redirect_url = Base64.encode(window.location.href);
        console.log("收到视频聊天",data);
        if(XBS_APP.isNewApp){
            let app_api = data.isVideo==1?'xbs_call_video':'xbs_call_audio';
            XBS_APP._callApp(app_api,{
              "roomId":data.roomId,
              "userId":data.mid,
              "role":0,
              "userSig":data.userSig,
              "appid":data.appid,
              "remoteInfo":{
                "userName":data.nickname,
                "userImg":data.headimg,
              }
            });
        }else{
            router.push({
                path:'/wechat/video',
                query:data
              })
        }
        
    }

    return msg;
}



export default message