<template>
  <div id="app">
    <!-- 首屏欢迎页面 -->
    <!-- <welcome></welcome> -->
    <!--   -->
    <div
      class="outter"
      :class="{ hideLeft: $route.path.split('/').length > 2 }"
    >
      <!--通用头部-->
      <header
        class="app-header"
        :class="{ 'header-hide': !$store.state.headerStatus }"
        v-show="isShowTopBottom"
      >
        <wx-header :pageName="pageName"></wx-header>
      </header>
      <!--搜索框 只在“微信”和“通讯录”页面下显示-->
      <!-- <search v-show="$route.path.indexOf('contact') > -1"></search> -->
      <!--四个门面页 “微信” “通讯录” “发现” “我”-->
      <section class="app-content">
        <keep-alive v-if="isLoggedIn">
          <router-view name="default"></router-view>
        </keep-alive>
        <router-view name="default" v-if="!isLoggedIn"></router-view>
      </section>
      <!--底部导航 路由 -->
      <footer class="app-footer" v-show="isShowTopBottom">
        <wx-nav></wx-nav>
      </footer>


    </div>
    <!--其他店内页集合 有过渡效果-->
    <transition
      name="custom-classes-transition"
      :enter-active-class="enterAnimate"
      :leave-active-class="leaveAnimate"
    >
      <router-view name="subPage" class="sub-page"></router-view>
    </transition>

    <!-- 自动播放音频 -->
    <audio
      controls="controls"
      hidden
      src="./assets/mp3/newMessage2.wav"
      ref="audio"
    ></audio>

    <template v-if="isBindMobile">
      <div class="mobileZhe"></div>
    <div class="mobileBind flex fdc">
      <div class="title">温馨提示</div>
      <div class="content">您还没绑定手机号，为了不影响您错过重要消息，建议您尽快绑定</div>
      <div class="buttonBox flex fr ">
        <button type="button" class="btn_no" @click="isBindMobile = false">暂时不了</button>
        <button type="button" class="btn_yes" @click="gotoUrl('https://www1.hnbjsj.com/u/security-chphone.html')">立即前往</button>
      </div>
    </div>

    </template>


  </div>
</template>

<script>
import welcome from "./components/common/welcome.vue";
import WxHeader from "./components/common/wx-header";
import WxNav from "./components/common/wx-nav";
import search from "./components/common/search";
import mixin from "./vuex/mixin.js"; // 混合被单独放在 mixin.js 中管理


import { Notify } from "vant";

import Socket from "@/utils/socket";
import Storage from "@/utils/storage";
import { ApiGetMyInfo } from "./api/api/main";
window.mixin = mixin; // 将 混合/mixin 暴露在窗口对象中，某些组件需要时，直接提取 window.mixin
export default {
  name: "app",
  components: {
    WxHeader,
    WxNav,
    search,
    welcome,
    // iframeView,
  },
  data() {
    return {
      pageName: "",
      routerAnimate: false,
      enterAnimate: "", //页面进入动效
      leaveAnimate: "", //页面离开动效

      isLoggedIn: false,
      socket: null,
      isBindMobile:false,
    };
  },
  activated() {
    this.init();
  },
  mounted: function () {
    this.init();
  },
  computed: {
    // 是否有新消息
    newMessage() {
      return this.$store.state.msgList.newMessage;
    },
    // 最后的一条动态通知
    LastMessage() {
      return this.$store.state.LastMessage;
    },
    isShowTopBottom(){
      let path = this.$route.path
      let redata = false;
      console.log(path)
      if(['/login','/wechat/video'].indexOf(path)>-1){
        redata =  false
      }else{
        redata =  true
      }
      return redata
    }
  },
  methods: {
    //初始化
    init() {
      //自动连接服务器
      if (!this.socket) {
        this.socket = Socket("imservice.hnbjsj.com");
      }
      // 如果url地址栏有token 则保存起来
      this.getInfo();
    },
    //
    pushMessage() {
      let LastMessage = this.$store.state.LastMessage;
      let title = LastMessage.from_user_nickname || "客服系统";
      if (LastMessage.is_group == 1) {
        title = "群聊-" + title;
      }
      let body = "您有一条新消息";
      if (LastMessage.tag == "message" && LastMessage.msg) {
        body = "说：" + LastMessage.msg;
      } else if (LastMessage.tag == "online") {
        body = LastMessage.msg;
      }

      this.$nativeNotification &&
        this.$nativeNotification.push({
          title: title,
          body: body,
          tag: LastMessage.tag || "message", // 标签
          icon:
            LastMessage.from_user_headimg ||
            "https://s3.bmp.ovh/imgs/2022/03/e3fcc12399b93a70.jpg",
          data: { x: 1 },
        });
    },
    play() {
      this.$refs.audio.currentTime = 0; //从头开始播放提示音
      this.$refs.audio.play(); //播放
      this.$store.state.msgList.newMessage = false;
    },
    setToken(query) {
      console.log(query, "---设置新的token");
      Storage.set("token", query.token);
      //  获取用户的基本信息
      this.getInfo();
    },
    getInfo(){
      ApiGetMyInfo().then((res) => {
        if(!res){
          return false;
        }
        let data = res.data
        let uid = data.id;
        Storage.set("uid", uid);
        this.$store.commit("setData", { key: "uid", value: uid });
        this.$store.commit("setData", { key: "userInfo", value: data });
                // 如果没有手机号 或者 手机号不对 就提醒前往手机号绑定
        if(!data.mobile || data.mobile.length!=11){
          let res = Storage.get("mobileBind") || false;
          if(!res){
            this.isBindMobile = true;
            Storage.set("mobileBind",1,86400); 
          }
        }
      });
    },
    gotoUrl(url){
      location.href = url;
    }
  },
  watch: {
    // 监听 $route 为店内页设置不同的过渡效果
    $route(to, from) {
      let query = to.query;
      let token = Storage.get("token") || ""; // 原token
      let uid = Storage.get("uid") || ""; // 原token
      if (query && query.token) {
        //新token 有变化
        if (token != query.token || (query.uid && uid != query.uid)) {
          this.setToken(query);
        }
      }
      // 检查是否有登录
      if (token) {
        this.isLoggedIn = true;
        this.socket.openConnect();
      } else {
        this.isLoggedIn = false;
        this.socket.close(); // 断开连接
      }

      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      if (toDepth === 2) {
        this.$store.commit("setPageName", to.name);
      }
      //同一级页面无需设置过渡效果
      if (toDepth === fromDepth) {
        return;
      }
      this.enterAnimate =
        toDepth > fromDepth ? "animated fadeInRight" : "animated fadeInLeft";
      this.leaveAnimate =
        toDepth > fromDepth ? "animated fadeOutLeft" : "animated fadeOutRight";
      // 从店面页进入店内页 需要对店内页重新设置离开动效 因为他们处于不同 name 的 router-view
      if (toDepth === 3) {
        this.leaveAnimate = "animated fadeOutRight";
      }
    },
    // 新消息 发送通知
    newMessage(val) {
      if (val) {
        this.play();
      }
    },
    LastMessage(val) {
      this.pushMessage();
    },
  },
  unmounted() {
    alert("我要被销毁了");
  },
};
</script>
<style lang="less">
/*将公用的样式统一在此导入*/

@import "assets/css/base.css";
@import "assets/css/common.css";
@import "assets/less/wx-header.less";
/*阿里 fonticon*/

@import "assets/css/lib/iconfont.css";
/*过渡效果需要的动画库*/

@import "assets/css/lib/animate.css";
/*weui 样式库 非常适合高仿微信*/

@import "assets/css/lib/weui.min.css";

.mobileZhe{
  position: absolute;
    width: 100%;
    height: 100%;
    background-color: #04040494;
    z-index: 98;
}
.mobileBind {
    position: fixed;
    top: 30%;
    border: 1px solid #8b8b8b;
    background-color: #fff;
    padding: 0.30769rem;
    border-radius: 0.15385rem;
    z-index: 99;
    width: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
    .title{
      font-size: 20px;
    }
    .content{
      font-size: 16px;
    }
    .buttonBox{
      margin-top: 10px;
      button{
        margin-right: 10px;
        width: 100px;
        height: 35px;
        border: 0px;
        border-radius: 5px;
      }
      .btn_yes{
        background-color: #50a4f3;
        color: #fff;
      }
    }
}

</style>