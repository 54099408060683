import axios from "axios";
import Storage from '../utils/storage'
import qs from 'qs'
import router from "../router";
import { showToast } from '@/utils/comm';
// import { useRouter } from "vue-router";
import { Toast } from 'vant';

// const router = useRouter()
// 获取域名
import baseUrl from './config'

const GetQueryString = (name)=>{
    let url = window.location.href
    if(url.indexOf('?')==-1){
        return '';
    }
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = url.substring(url.indexOf('?')+1).match(reg);
     if(r!=null)return  unescape(r[2]); return null;
}

//延迟加载类  loading.open() 启用  loading.close() 关闭
const loading = {
    loadingInstance: null,
    timer: 0,//网络延迟时 才显示加载中
    open: function () {
        if (this.timer) {
            return;
        }
        this.timer = setTimeout(() => {
            this._load();
        }, 800)
    },
    close: function () {
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = false;
        }
        if (this.loadingInstance !== null) {
            Toast.clear
            
        }
        this.loadingInstance = null
    },
    _load: function () {
        if (this.loadingInstance == null) {
            this.loadingInstance = Toast.loading({
                message: '加载中...',
                forbidClick: true,
              });
        }
    },
}
const setUrl = (e,t)=>{
    return "".concat(e).concat(e.indexOf("?")>-1?"&":"?").concat(t)
}
const request = (config)=>{
    const instance = axios.create({
        baseURL:baseUrl,
        timeout:50000,
        headers:config?.headers || {
            'X-Requested-With': 'foobar',
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
    // console.log(baseUrl,'---baseUrl')
    //请求拦截器
    instance.interceptors.request.use(config=>{
        loading.open()
        config.url = setUrl(config.url,"_time=".concat(Date.now()));
        let token = GetQueryString('token') || Storage.get('token') 
        config.headers['token'] = token
        // 如果不存在  就尝试在url中获取
        if(!config.headers['token']){
            config.headers['token'] = router.app._route.query?.token || ''
        }
        // config.url += (config.url.indexOf('?')==-1?'?':'&') + 'token='+ token
        if(config.method ==='post'){
            if (config.headers['Content-Type'] !== 'application/json') {
                config.data = qs.stringify(config.data);
            }
        }
        return config
    },error=>{
        return Promise.reject(error)
    })

    //响应拦截器
    instance.interceptors.response.use(response=>{
        loading.close();
        const code = response.data.code
        if(code==1){
            return response.data
        }else{
            showToast(response.data.msg)
            //如果请求配置中注明需要返回上一页
            if(config.option?.isBack==1){
                setTimeout(()=>{
                    router.back()
                },1500)
            }else if(config.option?.reject ==1){
                //如果错误需要抛回处理
                return Promise.reject(response.data);
            }else if(code==401){
                //Storage.get('token');
                if(router.app._route.path!='/login'){
                    //请登录
                    router.replace({
                        path:'/login'
                    })
                }
            }
            //return Promise.reject(response.data);
        }
    },(error)=>{
        console.log('错误拦截',333333)
        Promise.reject(error);
    })

    //发送请求
    return instance(config)
}
export default request;