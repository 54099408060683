import http from '../http'

// 获取会话列表
export const ApiGetMessageList = (data) => { return http.post('/api/message/index',data)} 

//获取通讯录列表
export const ApiGetFriendList = (data) => { return http.get('/api/index/getFriendList',data)} 

// 发送消息
export const ApiSendMessage = (data) => { return http.post('/api/message/sendMessage',data)} 

export const ApisetRead = (data) => { return http.get('/api/message/setRead',data)} 



//修改设置 例如 置顶 以及 消息免打扰
export const ApiSetUp = (data) =>{ return http.post('/api/message/SetUp',data)}



// 获取群组信息
export const ApiGetGroupInfo = (data) => { return http.post('/api/group/GetGroupInfo',data)} 

//创建群组 and 群组加入新成员
export const ApiCreateGroup = (data)=>{ return http.post('/api/group/CreateGroup',data)}

// 群组 删除成员
export const ApiCelGroupUser = (data)=>{ return http.post('/api/group/delGroupUser',data)}

//退出群组
export const ApiLogoutGroup = (data) =>{ return http.post('/api/group/logoutGroup',data)}
