<template>
  <div id="wx-nav">
    <nav>
      <router-link to="/" tag="dl" exact replace>
        <dt class="iconfont icon-wechat">
          <i class="new-msg-count" v-show="msgNumberAll">{{ msgNumberAll }}</i>
        </dt>
        <dd>会话列表</dd>
      </router-link>

      <router-link to="/contact" tag="dl" replace>
        <dt class="iconfont icon-contact">
          <i class="new-msg-count" v-show="newFriendNumber">{{
            newFriendNumber
          }}</i>
        </dt>
        <dd>通讯录</dd>
      </router-link>

      <!-- <router-link to="/explore" tag="dl">
            <dt class="iconfont icon-find" >
                <i class="new-msg-dot"></i>
            </dt>
            <dd>发现</dd>
        </router-link> -->

      <router-link to="/self" tag="dl" v-if="false">
        <dt class="iconfont icon-me">
          <!--<i class="new-msg-dot"></i>-->
        </dt>
        <dd>我</dd>
      </router-link>


      <dl @click="gotoUrl('https://www1.hnbjsj.com/u')" tag="dl" >
        <dt class="iconfont icon-me">
          <!--<i class="new-msg-dot"></i>-->
        </dt>
        <dd>我的</dd>
      </dl>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    //所有未读消息  msg_reda_number
    msgNumberAll() {
      let number = 0;
      for (var i in this.$store.state.msgList.baseMsg) {
        let item = this.$store.state.msgList.baseMsg[i];
        number += item.msg_reda_number;
      }
      return number;
    },
    newFriendNumber() {
      return this.$store.state.newFriendNumber;
    },
  },
  mounted() {
    for (var i in this.$store.state.msgList.baseMsg) {
      if (
        this.$store.state.msgList.baseMsg[i].read === false &&
        this.$store.state.msgList.baseMsg[i].quiet === false
      ) {
        this.$store.commit("addNewMsg");
      }
    }
  },
  methods:{
    gotoUrl(url){
      location.href = url;
    }
  }
};
</script>
<style lang="less">
@import "../../assets/less/wx-nav.less";
</style>