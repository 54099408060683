import http from '../http'

// 获取会话列表
export const ApiGetDialogueList = (data) => { return http.get('/api/index/getDialogueList',data)} 

//获取通讯录列表
export const ApiGetFriendList = (data) => { return http.get('/api/index/getFriendList',data)} 

//获取群组列表
export const ApiGetGroupList = (data) => { return http.get('/api/index/getGroupList',data)} 

// 获取个人信息
export const ApiGetMyInfo = (data) => { return http.get('/api/index/getMyInfo',data)} 

// 创建视频语音房间
export const ApiCreateRoom = (data) => { return http.post('/api/RoomList/add',data)} 
export const ApiLeaveRoom = (data) => { return http.post('/api/RoomList/leave',data)} 

