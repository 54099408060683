// app交互方法

/**
 * interactApp.js
 * xbs_getTicket  获取ticket
 *      login_ticketback  ticket回调 @params ticket 字符串
 * isLogin   是否登录
 *      login_callback  登录回调 @params isLogin 是否登录 1 or 0
 * xbs_joinLogin  打开登录页面
 * xbs_getUserInfoRequest 获取用户信息
 *       xbs_getUserInfoCallback 用户信息回调 @param {userInfo} 
 * xbs_closeWebView  关闭窗口
 * xbs_goBack  返回上一页
 * xbs_changeTitle  设置标题 @params {title}
 * xbs_seeBigPhoto  查看图片 @params {imageArr,index}
 * xbs_copyUrl    复制文字 @params {urlStr}
 * xbs_callPhone   打电话 @param {mobile}
 * xbs_uploadPhoto  上传图片 @params {allowsEdit}
 *      photo_callback 上传回调 @param url 成功的图片路径
 * xbs_download  下载文件 @param {file} 文件地址
 * xbs_logout   退出当前用户
 * xbs_setLoginInfo 登录成功  传递登录完成的参数 @params {}
 * 
 * xbs_orderPay  订单支付 @params {id,type} id：订单id type：订单类型  传递给支付接口即可
 *      orderPay_callback 订单支付回调 @params {msg}   msg取值范围： success/error
 * 
    xbs_startRecord   开始录制
    xbs_closeRecord  取消录制
    xbs_stopRecord   录制完成  
        xbs_stopRecordCallback  录制完成回调
 * 
 */
        // 旧版接口
        import XBSClass from './interactApp_old';

        // var XBS_APP = function(){}
        
        // 对不支持Object.assign方法的环境进行兼容
        if (typeof Object.assign != 'function') {
            Object.assign = function (target) {
                'use strict';
                if (target == null) {
                    throw new TypeError('Cannot convert undefined or null to object');
                }
                target = Object(target);
                for (var index = 1; index < arguments.length; index++) {
                    var source = arguments[index];
                    if (source != null) {
                        for (var key in source) {
                            if (Object.prototype.hasOwnProperty.call(source, key)) {
                                target[key] = source[key];
                            }
                        }
                    }
                }
                return target;
            }
        }
        

        console.log('MY_APP初始化')
        // XBS_APP = XBSClass.factory();
        
        var bjApp = function () {
            var ua = window.navigator.userAgent.toLowerCase();
            var _XBS_APP = XBSClass.factory();
            // 内置一个简易的toast
            var toast = {
                isInit: false,
                hideTimeout: null,
                _init: function () {
                    var toastNode = document.createElement('div');
                    toastNode.innerHTML = '<span class="text">默认提示</span>';//设置HTML模板，可以根据需求设计
                    toastNode.setAttribute('id', 'myToastBox');
                    toastNode.setAttribute('style', "position:absolute;display:none;left:50%;bottom:10px;z-index:9999;margin:auto;padding:5px 10px;transform:translate(-50%,-50%);min-width:120px;min-height:20px;line-height:20px;border-radius:5px;text-align:center;color:#fff;background-color:rgba(0,0,0,0.5)");// 直接设置样式
                    toastNode.style.display = 'none';//设置隐藏，默认隐藏
                    document.body.appendChild(toastNode);//添加到body下面
                    this.isInit = true;
                },
                show: function (text) {
                    // 初始化
                    if (!this.isInit) this._init();
                    if (this.hideTimeout) {//判断当前是否有弹出框，有的话先关闭当前
                        clearTimeout(this.hideTimeOut);
                        this.hideTimeOut = null;
                    }
                    if (!text) {//判断传入提示文本是否为空，是的话返回
                        console.log('text为空');
                        return;
                    }
                    var toastNode = document.getElementById('myToastBox');
                    if (!toastNode) {//判断toast是否初始化
                        console.log('未初始化');
                        return;
                    }
                    var toastText = toastNode.querySelector('.text');
                    toastText.innerHTML = text || '';//找到toast设置显示文本
                    toastNode.style.display = 'block';//设置toast为显示状态
                    this.hideTimeout = setTimeout(function () {//timeout设置多久后隐藏
                        toastNode.style.display = 'none';
                        toast.hideTimeout = null;
                    }, 2000);
                },
            }
        
            // 循环输出提示信息
            function _alert(msg) {
                if (toast.hideTimeout) {
                    // 上一条显示 等待中
                    setTimeout(() => {
                        _alert(msg);
                    }, 500);
                } else {
                    toast.show(msg);
                }
            }
        
            let callbackId = 1;
            var _bjApp = {
                Debug: false,
                // 判断是否是自家App
                isAppUA: /xbs_app/.test(ua),
                isAndroid: /android/.test(ua),
                isIOS: /iphone|ipad|ipod/.test(ua),
                isNewApp: /xbs_app_new/.test(ua),// 要兼容一下以前的 所以 这儿增加一个新app
                _createJSFcun(callName, callBack, isLasting) {
                    isLasting = isLasting || false;
                    if (typeof window[`${callName}`] == "function") {
                        delete window[`${callName}`];
                    }
                    if (typeof callBack === 'function') {
                        window[`${callName}`] = function (args) {
                            callBack(args);
                            // 如果不需要持久化 用完即时销毁
                            if (!isLasting) {
                                delete window[`${callName}`];
                            }
                        }
                    }
                },
                _callMethod(config) {
                    const callbackName = `__native_callback_${callbackId++}`;
                    // 注册全局回调函数
                    if (typeof config.callback === 'function') {
                        const callback = config.callback.bind(config);
                        window[`${callbackName}`] = function (args) {
                            callback(args);
                            //注销
                            delete window[`${callbackName}`];
                        }
                        config.callback = callbackName;
                    } else {
                        config.callback = '';
                    }
                    // 通过js 注入的全局对象
                    window.AppSDK.postMessage(JSON.stringify(config));
                },
                // 最终调用的方法
                _callApp(funName, data, callback) {
                    callback = callback || null;
                    console.log('APP调用', funName, data, this.isAppUA ? 'APP环境' : '非APP环境', ua)
                    if (!this.isAppUA) {
                        if (this.Debug)
                            _alert(funName + "非App访问")
                        return false;
                    }
                    try {
                        data = data || null;
                        if (this.isNewApp) {
                            this._callMethod({
                                mothod: funName,
                                // mothods:funName,
                                params: data,
                                callback: callback,
                            })
                        } else {
                            _XBS_APP._callApp(funName, data);
                        }
                        if (this.Debug) _alert(funName + " 调用成功")
                        return true;
                    } catch (e) {
                        console.log(e, '---app调用失败')
                        console.log(e.name, e.message, "调用的错误信息")
                        if (this.Debug) _alert(funName + " 调用失败")
                        return false;
                    }
                    return _bjApp;
                },
                // 上传图片
                uploadImage(type, allowsEdit, cb) {
                    this._callApp("xbs_uploadPhoto", { type: type, allowsEdit: allowsEdit }, cb);
                },
                // 获取ticket
                TICKET: '',
                getTicket(cb) {
                    if (this.TICKET) {
                        typeof cb == "function" && cb(this.TICKET);
                        return;
                    }
                    this._callApp("xbs_getTicket", '', cb);
                },
                isLogin: null,
                // 是否登录
                checkLogin(cb) {
                    if (this.isLogin != null) {
                        typeof cb == 'function' && cb(this.isLogin);
                        return this.isLogin;
                    }
                    this._callApp('isLogin', '', cb);
                },
                // 打开登录
                openLogin() {
                    this._callApp("xbs_joinLogin");
                },
                //关闭窗口
                closeWindow() {
                    this._callApp("xbs_closeWebView");
                },
                // 返回上一页
                goBack() {
                    this._callApp("xbs_goBack");
                },
                //复制文字
                copyText(text) {
                    this._callApp("xbs_copyUrl", { urlStr: text });
                },
                //打电话
                callPhone(mobile) {
                    this._callApp('xbs_callPhone', { mobile: mobile });
                },
                //发起支付
                orderPay(params, cb) {
                    return this._callApp("xbs_orderPay", params, cb);
                },
                // 文件下载
                download(file) {
                    this._callApp('xbs_download', { file: file });
                },
                // 开始录音
                startRecord() {
                    this._callApp('xbs_startRecord');
                },
                // 结束录音
                stopRecord(cb) {
                    this._callApp('xbs_stopRecord', '', cb);
                },
                // 放弃录制
                closeRecord() {
                    this._callApp('xbs_closeRecord');
                },
                // 有了新消息 推送给app app再播放声音
                pushAppNew() {
                    this._callApp("xbs_new_message");
                },
                // 传递用户信息 给予app获取
                pushInfoApp(funcName, userInfo) {
                    // 注册方法给app调用
                    this._createJSFcun(funcName, () => {
                        // 使用回调将信息传递回去
                        _bjApp._callApp("xbs_" + funcName, userInfo);
                    })
                },
                // 查看预览文件  主要试了pdf 是可以的
                lookFile(fileUrl,fileName){
                    this._callApp('xbs_lookFile',{fileUrl,fileName});
                }
        
            }
            return _bjApp;
        }
        
        var XBS_APP = new bjApp();
        
        //注册信息 app获取信息
        // $.get("/include/ajax.php?service=api&action=getUserLoginInfo",function(res){
        //     if(res.code==100){
        //         XBS_APP.pushInfoApp("getUserInfo",res.data);
        //     }
        // });
        
        
        export default XBS_APP;