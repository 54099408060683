<template>
  <!--复兴性高，数据交互比较频繁-->
  <div id="wx-header">
    <!--右上角图标-->
    <div class="other">
      <!--只在“微信”页显示 更多图标-->
      <span class="iconfont icon-tips-jia" v-show="$route.path==='/'" v-on:click="$store.commit('toggleTipsStatus')"></span>
      <!--只在“通讯录”页显示 显示添加好友图标-->
      <router-link tag="span" to="/contact/add-friend" class="iconfont icon-tips-add-friend" v-show="$route.path==='/contact'"></router-link>
      <!--只在“添加朋友”页显示 -->
      <span v-show="$route.path==='/contact/new-friends'">添加朋友</span>
      <!--下面这个好像有些多余 sad -->
      <span class="iconfont icon-chat-friends" v-show="$route.path==='/wechat/dialogue'"></span>
      <!-- 更多图标的菜单 附带过渡效果-->
      <ul class="tips-menu" :class="[$store.state.tipsStatus?'tips-open':'tips-close']" v-on:click="$store.commit('toggleTipsStatus', -1)">
          
          <router-link tag="li" to="/wechat/dialogue/dialogue-info/add"> 
          <span class="iconfont icon-tips-xiaoxi"></span>
          <div>发起群聊</div>
           </router-link>

        <router-link tag="li" to="/wehchat/add-friend"> 
          <span class="iconfont icon-tips-add-friend"></span>
          <div>添加朋友</div>
        </router-link>
        <li @click="deleteMsgEventAll"> 
          <span class="iconfont icon-tips-saoyisao"></span>
          <div>清空列表</div>
        </li>
        <!-- <li> <span class="iconfont icon-tips-saoyisao"></span>
          <div>扫一扫</div>
        </li>
        <li> <span class="iconfont icon-tips-fukuan"></span>
          <div>收付款</div>
        </li> -->
      </ul>
      <!--<div class="tips-masker" v-show="tips_isOpen"></div>-->
    </div>
    <div class="center">
      <!-- <transition name="fade">
          <div class="iconfont icon-return-arrow" style="left: 10px;position: absolute;font-size: 16px;" v-on:click="goBack" v-show="$route.path.split('/').length>2"><span>{{$store.state.backPageName}}</span></div> 
      </transition>-->
      <!--显示当前页的名字-->
      <span>{{$store.state.currentPageName}}</span>
      <!--微信群 显示群名以及成员人数 好像和 dialogue 组件 写重了 sad -->
      <span class="parentheses" v-show='$route.query.group_num&&$route.query.group_num!=1'>{{$route.query.group_num}}</span>
    </div>
  </div>
</template>
<script>
import { ApiSetUp } from '@/api/api/message'
    export default {
        props: ["pageName"],
        data() {
            return {
                // 暂且用不到
                chatCount: true
            }
        },
        methods: {
            // 暂且用不到 先留着
            goBack() {
                this.$router.go(-1)
                    //保证返回操作后正确显示页面名称
                    // this.$store.commit("setPageName", this.$store.state.backPageName)
            },
            //批量清理会话列表
            deleteMsgEventAll(){
              if (confirm("是否要清空会话列表，清空列表，不会删除聊天记录")) {
                let baseMsgList = this.$store.state.msgList.baseMsg
                let midList = []
                for(let i in baseMsgList){
                  midList.push(baseMsgList[i].mid)
                  baseMsgList[i].is_show = 0 // 从会话列表移除
                  baseMsgList[i].msg_reda_number= 0  // 清空未读数量
                  // console.log(baseMsgList[i],'---baseMsgList')
                }
                let params = {
                  mid:midList.join(','),
                  field:'is_show',
                  value:0,
                }
                // console.log(params,'--情况')
                ApiSetUp(params).then(({ msg }) => {});
              }

                // ApiSetUp(params).then(({ msg }) => {});
                // if (confirm("删除后，仅会在对话列表删除，不会删除聊天记录")) {
                //   this.item.is_show = 0; // 从会话列表移除
                //   this.item.msg_reda_number = 0; // 清空未读数量
                //   let params = {
                //     mid: this.item.mid,
                //     field: "is_show",
                //     value: 0,
                //   };
                //   //删除会话列表
                //   ApiSetUp(params).then(({ msg }) => {});
                //   event.target.parentNode.parentNode.firstChild.style.marginLeft =
                //     0 + "px";
                // }
            }
        }
    }
</script>
<style>

</style>