import request from './request.js'
import axios from 'axios'
// 获取域名
import baseUrl from './config'

const get = (url,params,option)=>{
    let config = {
        method:'get',
        url:url,
        option
    }
    // params.admin_id = 24
    if(params){
        params['admin_id'] = 24
    }else{
        params = {admin_id:24}
    }
    config.params = params
    return request(config)
}

const post = (url,params,option)=>{
    let config = {
        method:'post',
        url:url,
        option
    }
    if(params){
        params['admin_id'] = 24
    }else{
        params = {admin_id:24}
    }
    config.data = params
    return request(config);
}

//文件/图片 上传
const upImage = (url,param)=>{
    let config = {
        method:'post',
        url:url,
        data:param,
        headers:{
            "Content-Type":"multipart/form-data",
            "X-Requested-With":'foobar2'
        },
    }
    return axios.post(baseUrl+url, param, config)
}

export default{
    get,post,upImage
}