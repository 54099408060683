import Storage from './storage'
import { Toast } from 'vant';

//文字转大写
export const daxie = (str)=>{
  if(!str) return ''
    str = str.replace(str[0],str[0].toUpperCase());
    return str
}

//检查是否是微信浏览器
export const isWeixin = ()=>{
	var ua = navigator.userAgent.toLowerCase();
	if (ua.match(/MicroMessenger/i) == "micromessenger") {
		return true;
	} else {
		return false;
	}
}


/**
 * 传入分页数据  判断是否加载完成
 * @param {*} data 
 */
export const isNotData = (data)=>{
  let last_page = data.last_page
  if(data.current_page >= last_page){
    return true;//加载完成
  }else{
    return false;
  }
}

const toString = (data) => {
    return JSON.stringify(data);
  }
  //提示框
  export const showToast = (message, icon, time) => {
    icon = icon || 0
    time = time || 1500
    icon = icon == 0 ? 'none' : (icon == 1 ? 'success' : icon)
    const content = typeof message === 'string' ? message : toString(message);
    if(icon==0){
        Toast(message);
    }else{
        if(icon == 1){
          Toast.success(message);
        }else{
          Toast(message);
        }
    }
  }
  
  export const setTicket = async (query) => {
    // const store = useStore();
    //如果存在ticket
    if (query && query.token) {
      
      let old_token = Storage.get('token') || ''
      console.log('设置token...old_token:', old_token)
      if (old_token != query.token) {
        // console.log('更新token',query.token)
        Storage.set('token', query.token)
        // store.commit('memberStore/SetToken', query.token)
        //更新用户信息
        // const data = await getUserInfo(true)
      }
    }
  }
  
  //传递个人信息  前往在线客服系统  联系平台客服
  export const getImservice = (userInfo, option) => {
    userInfo.userid = userInfo.id
    IMLink(userInfo, option);
  }
  
  /**
   * 发起会话连接
   * @param userInfo 用户基本信息
   * @param option  咨询的客服组  {depart_id:'客服组',staff_id:'客服id',to_id:'指定用户id'}
   * (depart_id && staff_id)  和 (to_id)  参数二选一
   * @constructor
   */
  function IMLink(userInfo, option) {
    var secret = 'e10adc3949ba59abbe56e057f20f883e';//你的密钥
    console.log(userInfo, userInfo.userid, '--userinfo')
    if (!userInfo || !userInfo.userid) {
      alert('请先登录，才能联系客服哦~');
      location.href = "/";
      return;
    }
    var params = {
      secret: secret,
      userid: userInfo.userid,
      username: userInfo.nickname,//用户昵称
      mobile: userInfo.phone,//用户手机号
      depart_id: 0,//客服组id
      staff_id: 0,//客服id
      to_id: 0,
      headimg: userInfo.photo,//用户头像
      extend: '',
    }
    params = Object.assign({}, { ...params }, { ...option })
  
    var url = "https://imservice.hnbjsj.com/home/login/AccessLogin.html" + queryParams(params, true)
    location.href = url
  }
  
  /**
   * 对象转url参数
   * @param {*} data
   * @param {*} isPrefix
   */
  function queryParams(data, isPrefix) {
    isPrefix = isPrefix ? isPrefix : false
    let prefix = isPrefix ? '?' : ''
    let _result = []
    for (let key in data) {
      let value = data[key]
      // 去掉为空的参数
      if (['', undefined, null].includes(value)) continue;
      if (value.constructor === Array) {
        value.forEach(_value => {
          _result.push(encodeURIComponent(key) + '[]=' + encodeURIComponent(_value))
        })
      } else if (value.constructor === Object) {
        let _result_item = []
        for (let item in value) {
          _result_item.push('"' + encodeURIComponent(item) + '":"' + encodeURIComponent(value[item]) + '"')
        }
        _result.push(encodeURIComponent(key) + '=' + encodeURIComponent("{" + _result_item.join(',') + "}"))
      }
      else {
        _result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
      }
    }
    return _result.length ? prefix + _result.join('&') : ''
  }
  
  /**
 * 打开文件
 */
export const open_file = (file)=>{
  // console.log(window.__wxjs_environment,'---window.__wxjs_environment')
  if (window.__wxjs_environment == 'miniprogram' || /miniProgram/i.test(navigator.userAgent) ) {
      let wx = require('jweixin-module');
      // let params = {
      //   url: file.url||'',//文件路径
      //   fileExt: file.fileExt||'',//文件后缀
      //   fileName: file.fileName||'',//文件名称
      //   open: file.open||'',
      // }
      file.returnUrl = window.location.href
      var prefix
      if(file.appId){
        // 打开外部小程序
        prefix = '/pages/gotoXCX/gotoXcx?'
      }else{
        // 打开自身小程序 下载页面
        prefix = '/pages/download/download?'
      }
      //获取返回的方法
      var path = prefix + ObjToStr(file);
      wx.miniProgram.redirectTo({url: path});
      return true;
  }else{
    return false;
  }
}

  // 字符串转 base64
//  const encode = (str)=>{
//   var encode = encodeURI(str)
//   return btoa(encode)
// }

//对象转字符串
const ObjToStr = (obj) => {
  const params = []
  Object.keys(obj).forEach((key) => {
      let value = obj[key]
      // 如果值为undefined我们将其置空
      if (typeof value === 'undefined') {
          value = ''
      }
      // 对于需要编码的文本（比如说中文）我们要进行编码
      params.push([key, encodeURIComponent(value)].join('='))
  })
  return params.join('&')
}


  //将本地文件上传至服务器
  export function uploadFile(tempFilePath, callBack) {
    //通过fetch可以拿到真正的blob
    fetch(tempFilePath)
      .then((fetcRes) => {
        return fetcRes.blob(); //返回blob对象格式
      })
      .then((blob) => {
        let reader = new window.FileReader();
        //blob 转为base64 格式
        reader.onloadend = async () => {
          const base64Data = reader.result; //base64的图片格式
          let size = getImgSize(base64Data); //文件大小  kb
  
          //文件最大不能超过 10 MB
          if (size / 1000 > 10) {
            size = parseInt(size / 1000)
            showToast("上传失败，您上传的图片文件过大！当前大小：" + size + "MB");
            return;
          }
          //base64上传 并返回服务器图片路径
          let { data } = await upImage2(base64Data);
          if (data.code == 1) {
            let imageUrl = data.data
            //拿到上传的图片
            // state.userInfo.avatar = imageUrl
            callBack && callBack(imageUrl) //回调
            // showToast("上传成功！", 1);
          } else {
            showToast("上传失败：" + data.msg);
          }
        };
        reader.readAsDataURL(blob);
      });
  };
  
  //计算文件的大小  
  function getImgSize(str) {
    //获取base64图片大小，返回KB数字
    str = str.replace('data:image/jpeg;base64,', '');//这里根据自己上传图片的格式进行相应修改
    var strLength = str.length;
    var fileLength = parseInt(strLength - (strLength / 8) * 2);
    // 由字节转换为KB
    var size = "";
    size = (fileLength / 1024).toFixed(2);
    return parseInt(size);
  }