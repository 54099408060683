import Vue from 'vue'
import Vuex from 'vuex'
import OfficialAccounts from "./official-account" //存放所有关注的公众号
import contact from './contacts' //存放所有联系人的数据
import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"
import Storage from '@/utils/storage'

Vue.use(Vuex)
    // 统一管理接口域名 
let apiPublicDomain = '//vrapi.snail.com/'
const state = {
    uid:Storage.get('uid')||0,//自己的用户id
    userInfo:{},//个人信息
    mid:0,//当前聊天对象  如果不是当前对象 那么就要加上聊天标识
    currentLang: "zh", //当前使用的语言 zh：简体中文 en:英文 后期需要
    newMsgCount: 0, //新消息数量
    allContacts: [], //所有联系人
    OfficialAccounts: OfficialAccounts, //所有关注的公众号
    currentPageName: "博江-即时通讯", //用于在wx-header组件中显示当前页标题
    //backPageName: "", //用于在返回按钮出 显示前一页名字 已遗弃
    headerStatus: true, //显示（true）/隐藏（false）wx-header组件
    tipsStatus: false, //控制首页右上角菜单的显示(true)/隐藏(false)
    LastMessage:'',//全局最新的一条消息
    // 所有接口地址 后期需要
    apiUrl: {
        demo: apiPublicDomain + ""
    },
    msgList: {
        newMessage:false,//是否有新消息  新的好友添加提示
        // stickMsg: [], //置顶消息列表 后期需要
        baseMsg: [],//消息会话列表
    },
    //消息列表  对方会话id -> 聊天记录
    messageList:[],
    groupList:[],//群组列表
    kefuList:[],//客服列表
    newFriendNumber:0,//新好友申请数量
    refreshView:{
        huihuaStatus:false,//会话列表
        contactStatus:false,//通讯录刷新
    },
    msgMp3:0,// 全局监听mp3音频播放 唯一
    // 全局的ifram框体
    iframe:{
        // iframe 地址
        url:'',
        // 是否显示
        show:true,
    }
}
export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})