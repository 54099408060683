import Storage from './storage'
import message from './message'
import { Notify } from "vant";

//定义 websocket
let Socket = function (wsUrl, port) {
    let _wsUrl
    if(port){
        _wsUrl = 'ws://' + wsUrl + ':' + port
    }else{
        _wsUrl = 'wss://' + wsUrl+'/wss'
    }
    let timer, timer2
    const s = {
        //连接
        ws: false,
        isAuto: true,  // 是否进行自动重连
    }

    //发送消息对象
    let _msg = message()

    //连接服务器端
    let connect = function () {

        //不进行自动重连
        if (!s.isAuto) return;

        let ws = new WebSocket(_wsUrl)

        //绑定事件
        ws.onopen = onOpen
        ws.onmessage = onMessage
        ws.onclose = onClose
        ws.onerror = onError

        //将ws 句柄 挂载在对象上
        s.ws = ws
    }

    //连接成功 发送登录请求
    const onOpen = function () {
        let ticket = Storage.get('token')
        var login_data = {
            type: 'login',
            ticket: ticket,//ticket 中已经包含需要的个人基本信息
        }
        s.send(login_data)
        heartbeat();//开启心跳

        //关闭错误提示
        Notify.clear();
    }

    //绑定新消息
    const onMessage = function (e) {
        let msgData = JSON.parse(e?.data)
        switch (msgData['msgType']) {
            case 'msg':
                //收到新的消息
                let isRead = _msg.addMessageList(msgData['data'])
                if (isRead) {
                    //如果是已读  则需要通知服务器
                    s.send({ type: 'read', mid: isRead })
                }
                break;
            //好友上下线
            case 'upUserStatus':
                _msg.upUserStatus(msgData['data'])
                break;
                // 群昵称变动
            case 'upGroupName':
                _msg.upGroupName(msgData['data'])
                break;
                //新的好友申请
                case 'upNewFriendNumber':
                _msg.upNewFriendNumber(msgData['data'])    
                break;
                // 收到提示 设备发出声音提醒
                case 'tips':

                    break;
                    // 收到音视频提示
                    case 'getVideoMsg':
                        _msg.getVideoMsg(msgData['data'])
                        break;

        }
    }

    //绑定关闭的方法
    const onClose = function () {
        if (s.isAuto) {
            Notify({ message: "服务器连接断开", color: '#fff', background: '#666', duration: 30000 });
        }
        //清理心跳
        if (timer) {
            clearInterval(timer)
            timer = null
        }
        if (timer2) clearTimeout(timer2)
        //3秒后重连
        timer2 = setTimeout(() => {
            connect()
        }, 3000)
    }

    //绑定连接错误
    const onError = function () {
        if (s.isAuto) {
            Notify({ message: "服务器连接错误", color: '#fff', background: '#666', duration: 30000 });
        }
        if (timer2) clearTimeout(timer2)
        timer2 = setTimeout(() => {
            connect()
        }, 3000)
    }

    //心跳
    const heartbeat = function () {
        if (timer) clearInterval(timer)
        timer = setInterval(() => {
            s.send({ type: 'ping' })
        }, 10000)
    }

    //挂载发送消息事件
    s.send = function (data) {
        let datastr
        if (data.type) {
            datastr = JSON.stringify(data)
        } else {
            datastr = data
        }
        s.ws.send(datastr)
    }

    //主动断开连接
    s.close = function () {
        s.isAuto = false
        s.ws.close()
    }

    s.openConnect = function () {
        if(!s.isAuto){
            s.isAuto = true
            connect();//发起连接
        }
    }

    connect();//发起连接

    return s
}




export default Socket